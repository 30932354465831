<template>
    <v-container class="pa-4 py-8">
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-8">
            <v-icon>mdi-chart-bar-stacked</v-icon> View All KRI Coverage
            
        
            <div class="float-right row mr-2">
                <v-select
                    :items="returnDept"
                    v-model="deptFilter"
                    label="Select Department"
                    class="mr-4 col"
                    hide-details
                    outlined
                    style="width: 200px;"
                    v-show="returnORMPFilter"
                    dense
                    >
                </v-select> 
                <router-link :to="{name: 'AddKRICoverage'}" tag="button" >
                    <v-btn
                        color="primary"
                        dark
                        class="mb-2 col"   
                        >
                        <v-icon>mdi-plus</v-icon>
                        New KRI Coverage
                    </v-btn>
                </router-link>
            </div>
        </div>
        
        <v-data-table
            :headers="headers"
            :items="returnCoverage"
            sort-by="calories"
            class="elevation-1"
            >
           
        <template v-slot:item.actions="{ item }">
            <v-row class="ml-1">
                <router-link :to="{name: 'ViewAllKRI', params: {id: item['.key']}}">
                    <v-icon
                    small
                    >
                    mdi mdi-arrow-right-bold-circle
                    </v-icon>    
                </router-link> 
                <v-icon
                    small
                    @click="deleteItem(item)"
                    class="mr-2"
                    >
                    mdi-delete
                </v-icon>
            </v-row>
        </template>

        <template v-slot:item.CreatedAt="{ item }">
              {{$moment(item.CreatedAt.toDate()).format(' MMMM DD, YYYY / h:mm A')}}
          </template>

            <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="initialize"
            >
                Reset
            </v-btn>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    export default {
        data: () => ({
            deptFilter: 'SYSDEV',
            dialog: false,
            dialogDelete: false,
            headers: [
                {
                text: 'Date Covered',
                align: 'start',
                sortable: false,
                value: 'startDate',
                },
                { text: 'Created At', value: 'CreatedAt' },
                { text: 'Created By', value: 'CreatedBy' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            editedIndex: -1,
            editedItem: {},
        }),

  

        watch: {
        dialog (val) {
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },
        },

        created () {
        this.initialize()
        },

        firestore(){
            return {
                Coverage: this.$db.collection('KRICoverage'),
                Departments: this.$db.collection('Departments'),
                ORMP: this.$db.collection('ORMP'),
                ORMPLevels: this.$db.collection('ORMPLevels'),
            }
        },

        computed: {
            returnORMPFilter(){
                if(this.$store.getters['useraccount/isAuthenticated'].department !== 'RISK MANAGEMENT TEAM') {
                    return false
                }
                return true
            },
            returnDept(){
                let db = this.Departments.map(a=>{
                    return {
                        text: a.departmentName,
                        value: a.departmentName
                    }
                })
                return db
            },
            returnCoverage(){
                let before = this.Coverage
                let self = this

                let user = self.$store.getters['useraccount/isAuthenticated']
                
                if(user.department !== 'RISK MANAGEMENT TEAM'){
                    return before.filter(a=>{
                        return a.Department == user.department
                    })          
                }

                if(self.deptFilter !== 'ALL DEPARTMENTS'){
                    return before.filter(a=>{
                        return a.Department == self.deptFilter
                    })
                }

                return before
            }
        },

        methods: {

        editItem (item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        findORMPItems(item, collection){
        let arr = []
        let filter = this[collection].filter(e => {
         return e.CoverageId === item['.key'] 
        })
        filter.forEach(e=> {
          arr.push(e['.key'])
        })
        return arr
        },

        async deleteItem (item) {
            let self = this
            let deletedORMPItems = this.findORMPItems(item, 'ORMP')
            let deletedORMPLevels = this.findORMPItems(item, 'ORMPLevels')

            const confirm = await this.$dialog.confirm({
                text: `Once this ORMP Coverage has been deleted all ORMP Rows and Levels will also be deleted and cannot be restored. Are you sure you want to continue?`,
                title: `WARNING: THIS ACTION CANNOT BE UNDONE`
            })

            if(confirm){
                 self.$db.collection('ORMPCoverage').doc(item['.key']).delete()
                 .then(()=>{
                  let deleteQuery = self.$db.collection('ORMP').where("CoverageId", "==", item['.key'])
                  deleteQuery.get().then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {
                      doc.ref.delete();
                    });
                  });
                 }).then(()=>{
                  let deleteQuery = self.$db.collection('ORMPLevels').where("CoverageId", "==", item['.key'])
                  deleteQuery.get().then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {
                      doc.ref.delete();
                    });
                  });
                 })
                 .then(async()=>{
                    await self.$store.dispatch('logs/createNotifs', {collection:'ORMPCoverage',documentKey:deletedORMPItems,module: 'ORMP',action: 'Deleted ORMP Coverage'}) 
                     await self.$store.dispatch('logs/createNotifs', {collection:'ORMPLevels',documentKey:deletedORMPLevels,module: 'ORMP',action: 'Deleted ORMP Levels'}) 
                    self.$dialog.notify.success(`Deleted Item from All ORMP's`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                     
                 })
            }
        },

        deleteItemConfirm () {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close () {
            this.dialog = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        save () {
                if (this.editedIndex > -1) {
                Object.assign(this.desserts[this.editedIndex], this.editedItem)
                } else {
                this.desserts.push(this.editedItem)
                }
                this.close()
            },
        },
    }
</script>
<style scoped>
a:link {
  text-decoration: none;
}
</style>